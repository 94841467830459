document.addEventListener('DOMContentLoaded', () => {
  const elements = document.getElementsByTagName('a');
  for(let i = 0, len = elements.length; i < len; i++) {
      const href = elements[i].href;
      if (href.includes('amazon.com')) {
        elements[i].onclick = function () {
          gtag('event', 'amazon.com', {
            'event_category': 'Amazon Links'
          });
        }
      } else if (href.includes('amzn.to')) {
         elements[i].onclick = function () {
          gtag('event', 'amazon.com', {
            'event_category': 'Amazon Links'
          });
        }
      } else if (href.includes('brownells.com')) {
         elements[i].onclick = function () {
          gtag('event', 'brownells.com', {
            'event_category': 'Brownells Links'
          });
        }
      } else if (href.includes('creedmoorsports.com')) {
         elements[i].onclick = function () {
          gtag('event', 'creedmoorsports.com', {
            'event_category': 'Creedmoor Sports Links'
          });
        }
      } else if (href.includes('eurooptic.com')) {
         elements[i].onclick = function () {
          gtag('event', 'eurooptic.com', {
            'event_category': 'EuropOptic Links'
          });
        }
      } else if (href.includes('natchezss.com')) {
         elements[i].onclick = function () {
          gtag('event', 'natchezss.com', {
            'event_category': 'Natchez Links'
          });
        }
      } else if (href.includes('sportsmans.com')) {
         elements[i].onclick = function () {
          gtag('event', 'sportsmans.com', {
            'event_category': 'Sportsmans Warehouse Links'
          });
        }
      } else if (href.includes('cabelas.com')) {
         elements[i].onclick = function () {
          gtag('event', 'cabelas.com', {
            'event_category': 'Cabelas Links'
          });
        }
      } else if (href.includes('wittmachine.net')) {
         elements[i].onclick = function () {
          gtag('event', 'wittmachine.net', {
            'event_category': 'Witt Machine Links'
          });
        }
      } else if (href.includes('aeroprecisionusa.com')) {
         elements[i].onclick = function () {
          gtag('event', 'aeroprecisionusa.com', {
            'event_category': 'Aero Precision Links'
          });
        }
      } else if (href.includes('faxonfirearms.com')) {
         elements[i].onclick = function () {
          gtag('event', 'faxonfirearms.com', {
            'event_category': 'Faxon Firearms Links'
          });
        }
      } else if (href.includes('tekmat.com')) {
         elements[i].onclick = function () {
          gtag('event', 'tekmat.com', {
            'event_category': 'TekMat Links'
          });
        }
      }


  }
});